import EntryProxy from '@/app/proxies/EntryProxy';
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        highscores: [
            { score: 10, firstname: 'een', nyxid: '1' },
            { score: 9, firstname: 'een', nyxid: '1' },
            { score: 8, firstname: 'een', nyxid: '123' },
            { score: 7, firstname: 'een', nyxid: '1' },
            { score: 6, firstname: 'een', nyxid: '1' }
        ],
        entry: {
        }
    },

    getters: {
        all: state => state.entry,
        aroundMe: ( state ) =>
        {
            const myIndex = state.highscores.findIndex( ( element ) =>
            {
                return element.nyxid === state.entry.nyxid;
            });
            const result = [];
            for( let i = myIndex - 2; i <= myIndex + 2; i++ )
            {
                let obj = false;
                if( state.highscores[i] )
                {
                    obj = { ...state.highscores[i], rank: i + 1 };
                }
                result.push( obj );
            }
            return result;
        }
    },

    mutations: {
        highscores: ( state, payload ) =>
        {
            Vue.set( state, 'highscores', [...payload] );
        },
        assign: ( state, payload ) =>
        {
            state.entry = Object.assign({}, state.entry, payload );
        }
    },

    actions: {
        clubpoints: ( context, payload ) =>
        {
            return new EntryProxy()
                .find( context.getters.all.nyxid )
                .then( response =>
                {
                    context.commit( 'assign', { clubpointsClaimed: response });
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        },
        merge: ( context, payload ) =>
        {
            context.commit( 'assign', payload );
        },

        highscores: ( context, payload ) =>
        {
            return new EntryProxy()
                .all()
                .then( response =>
                {
                    context.commit( 'highscores', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        },

        save: ( context, payload ) =>
        {
            context.commit( 'assign', payload );

            return new EntryProxy()
                .create( context.getters.all )
                .then( response =>
                {
                    context.commit( 'assign', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        }
    }
};
