import 'es6-promise/auto';

import Vue from 'vue';
import App from './App';

import router from '@/app/router';
import store from '@/app/store';
import VuexRouterSync from 'vuex-router-sync';

import Axios from 'axios';

VuexRouterSync.sync( store, router );

Axios.defaults.baseURL = window._rootData.apiUrl;
Axios.defaults.headers.common.Accept = 'application/json';
Axios.interceptors.response.use(
    response => response,
    ( error ) =>
    {
        return Promise.reject( error );
    }
);

Vue.$http = Axios;
Object.defineProperty( Vue.prototype, '$http', {
    get()
    {
        return Axios;
    }
});

Vue.config.productionTip = false;

// const urlParams = new URLSearchParams( window.location.search );

window.addEventListener( 'message', ( event ) =>
{
    console.log( 'message', event );
    try
    {
        const data = JSON.parse( event.data );
        if( data.n && data.f )
        {
            console.log( 'pushing', data );
            store.dispatch( 'entry/merge', {
                nyxid: data.n,
                firstname: data.f,
                club: data.c
            });

            store.dispatch( 'entry/clubpoints' );
        }
    }
    catch( e ) {}
}, false );

const urlParams = new URLSearchParams( window.location.search );

store.dispatch( 'entry/merge', {
    nyxid: urlParams.get( 'n' ),
    firstname: urlParams.get( 'f' ),
    club: urlParams.get( 'c' ) === '1',
    clubpointsClaimed: window._rootData.clubpoints
});

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>'
});
