/* eslint-disable */
import { gsap } from 'gsap';
import * as PIXI from 'pixi.js';
import { Howler, Howl } from 'howler';
import EventEmitter from 'eventemitter3';

Howler.volume( 0.7 );

PIXI.utils.destroyTextureCache();
PIXI.utils.skipHello();

export {
    gsap,
    PIXI,
    Howler,
    Howl,
    EventEmitter
};
