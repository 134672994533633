<template>
    <div class="main-layout">
        <rotate-screen :active="$route.name !== 'Landing'"></rotate-screen>
        <div class="content">
            <slot></slot>
        </div>

        <footer v-if="footerRoute">
            <div class="content-wrapper">
                <!-- <img class="footer-img" src="/static/img/logos/footer.png" /> -->
                <img class="footer-img" src="/static/img/logos/footer-big.png" />
                <p class="disclaimer">Voor het organiseren van Staatsloterij is aan Staatsloterij bv vergunning verleend door de Kansspelautoriteit, trekking Laan van Hoornwijck 55 te Rijswijk. <br />Deelname vanaf 18 jaar. Als je aan deze actie meedoet, dan accepteer je onze <a :href="avLink" target="_blank">actievoorwaarden</a>. <br class="hide-for-medium-up" /><a href="https://www.nederlandseloterij.nl/privacy" target="_blank">Privacy Statement</a> Nederlandse&nbsp;Loterij.</p>
            </div>
        </footer>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import RotateScreen from '@/app/components/RotateScreen';
    export default {
        components: {
            'rotate-screen': RotateScreen
        },
        data()
        {
            return {
                footerRoute: false
            };
        }
    };
</script>
