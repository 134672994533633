<template>
    <div id="app">
        <ynk-layout>
            <transition mode="out-in"
                        @enter="getAnimateIn"
                        @leave="getAnimateOut"
                        :css="false"
                        appear>
                <router-view :key="$route.path" ref="currentView" @overlay="onOverlay" @fadeInBgMusic="fadeInBgMusic"></router-view>
            </transition>
        </ynk-layout>
        <div class="overlayer" v-if="overlayComponent">
            <component :is="overlayComponent" @close="overlayComponent = undefined"></component>
        </div>
    </div>
</template>

<style lang="scss">
    @import './static/scss/app.scss';
    .overlayer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 4;
    }
</style>

<script>
    import { gsap, Howl } from '@/vendor';
    import Main from '@/app/layouts/Main';

    export default {
        name: 'App',

        components: {
            'ynk-layout': Main
        },

        data()
        {
            return {
                overlayComponent: undefined
            };
        },

        watch: {
            '$route.path': function( pVal )
            {
                this.overlayComponent = undefined;

                if( pVal === '/game' )
                {
                    this.sounds.bgMusic.fade( 0.8, 0, 2000 );
                }
            }
        },

        mounted()
        {
            window.addEventListener( 'resize', this.onResize );
            this.onResize();

            this.preloadSounds();

            if( this.$route.path !== '/game' )
            {
                this.sounds.bgMusic.play();
            }
        },

        methods: {
            onOverlay( overlay )
            {
                this.overlayComponent = overlay;
            },

            fadeInBgMusic()
            {
                this.sounds.bgMusic.fade( 0, 0.8, 2000 );
            },

            preloadSounds()
            {
                /* eslint-disable-next-line */
                this.sounds = {
                    coin: new Howl({ src: '/static/sounds/Coin2.wav' }),
                    clubpoint: new Howl({ src: '/static/sounds/Powerup5.wav' }),
                    flap: new Howl({ src: '/static/sounds/fladder.wav', volume: 0.4 }),
                    hit: new Howl({ src: '/static/sounds/hit.wav' }),
                    clock: new Howl({ src: '/static/sounds/clock1.wav' }),
                    lot_old: new Howl({ src: '/static/sounds/lot_old.mp3' }),
                    finish: new Howl({ src: '/static/sounds/finish.mp3' }),
                    beep: new Howl({ src: '/static/sounds/countdown-beep.wav', volume: 0.3 }),
                    longBeep: new Howl({ src: '/static/sounds/countdown-longbeep.wav', volume: 0.3 }),
                    bgMusic: new Howl({ src: '/static/sounds/fritsie_music.mp3', volume: 0.8, loop: true })
                };
            },

            onResize()
            {
                document.documentElement.style.setProperty( '--vh100', window.innerHeight + 'px' ); // fix for 100vh on iphones
            },

            getAnimateIn( el, done )
            {
                const tl = gsap.timeline({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateIn === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateIn(), 0 );
                }
                else
                {
                    done();
                }
            },

            getAnimateOut( el, done )
            {
                const tl = gsap.timeline({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateOut === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateOut(), 0 );
                }
                else
                {
                    done();
                }
            }
        }
    };
</script>
