import Vue from 'vue';
import Router from 'vue-router';

Vue.use( Router );

const appRouter = new Router({
    // mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Landing',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            }
        },
        {
            path: '/explain',
            name: 'Explain',
            component: () =>
            {
                return import( /* webpackChunkName: "explain" */ '@/app/pages/Explain' );
            }
        },
        {
            path: '/game',
            name: 'Game',
            component: () =>
            {
                return import( /* webpackChunkName: "game" */ '@/app/pages/Game' );
            },
            beforeEnter: ( to, from, next ) =>
            {
                if( !from.name )
                {
                    next( '/' );
                }
                else
                {
                    next();
                }
            }
        },
        {
            path: '/prijzen',
            name: 'Rewards',
            component: () =>
            {
                return import( /* webpackChunkName: "resuRewardslt" */ '@/app/pages/Rewards' );
            }
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
